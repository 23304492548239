import React from 'react';
import { GridItem, Section, Heading, Paragraph } from '@thepuzzlers/pieces';

import { SEO } from 'gatsby-theme-thepuzzlers-core';
import { useIntl } from 'gatsby-theme-thepuzzlers-intl';

const NotFoundPage = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <SEO title="404: Not found" />
      <Section>
        <GridItem
          sx={{
            display: 'flex',
            height: ['200vw', '50vw', '120vw'],
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center'
          }}>
          <Heading as="h1" type="h3">
            {formatMessage({
              id: 'notfound.header',
              defaultMessage: 'Not found.'
            })}
          </Heading>
          <Paragraph sx={{ mt: '15px' }}>
            {formatMessage({
              id: 'notfound.description',
              defaultMessage: 'You just hit a route that doesn&#39;t exist.'
            })}
          </Paragraph>
        </GridItem>
      </Section>
    </>
  );
};

export default NotFoundPage;
